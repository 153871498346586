<template>
  <div style="position: relative">
    <ReceiptPopover
      v-if="!showAddCard && !hero.Cancelled"
      style="position: absolute; top: -5px; right: 0px; z-index: 10"
      :hero="hero"
    />
    <div id="editableCardContainer" class="editableCardContainer p-2 d-flex flex-column align-items-center">
      <CardPreviewFromProps
        v-show="!buttonClick"
        class="my-0 my-lg-3"
        :hero="hero.Hero"
        :colour="hero.Colour"
        :sport="hero.Sport"
        :number="hero.Number"
        :first-initial="hero.FirstInitial"
        :second-initial="hero.SecondInitial"
        :cancelled="hero.Cancelled && !showAddCard"
        style="margin: auto 0 !important;width: 220px; max-height: 75%;"
      >
        <div
          v-if="showAddCard"
          class="d-none d-sm-flex flex-column align-items-center justify-content-center p-3 mr-2 mr-sm-3 cp emptyCardStyling"
          @click="addAnother()"
        >
          <span class="text-center primary-colour">ADD ANOTHER HERO</span>
          <i class="fa fa-plus primary-colour p-2" style="border-radius:50%; border: solid 3px;" />
        </div>
      </CardPreviewFromProps>
      <div
        v-if="!hero.Cancelled && !showAddCard"
        class="editableCardActionsContainer d-flex flex-column justify-content-around"
      >
        <span class="primary-colour text-center mt-1">{{ hero.ClubName }}</span>
        <div
          v-show="showNicknameInput"
          class="nicknameContainer my-2"
        >
          <mdb-input
            ref="initialNicknameInput"
            v-model="initialNickname"
            style="width: 100%"
            size="md"
            placeholder="NICKNAME"
            class="primary-colour greenForm m-0 text-center formTextAlign"
            @blur="saveBasketNickname()"
          />
        </div>
        <span v-show="!showNicknameInput && !buttonClick" class="text-center mt-1 white-text">{{ hero.Nickname ? hero.Nickname : 'ENTER NICKNAME BELOW?' }}</span>
        <!-- OPTIONS BUTTON -->
        <ButtonGroup
          v-if="!buttonClick"
          :buttons="editableCardOptionButtons"
          main-button-text="OPTIONS"
          class="mb-5"
          @cancel="showConfirmCancelHeroModal"
          @results="goToResults"
          @nickname="showNicknameInputElement()"
        />
      </div>
      <!-- REQUIRED FOR STYLING OF ADD ANOTHER HERO CARD -->
      <div v-else class="editableCardActionsContainer" />
    </div>
  </div>
</template>

<script>
import CardPreviewFromProps from '@/components/cardPreviews/cardPreviewFromProps.vue'
import basketService from '@/api-services/basket.service'
import { mapGetters } from 'vuex'
import ButtonGroup from '@/components/UI/buttonGroup.vue'
import ReceiptPopover from '@/components/results/receiptPopover.vue'

export default {
  name: 'MyHeroEditableCard',
  components: { CardPreviewFromProps, ButtonGroup, ReceiptPopover },
  props: {
    hero: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    showAddCard :{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editableCardOptionButtons: [
        {
          outerButtonClass: 'text-nowrap',
          buttonText: 'RESULTS',
          iconClass: 'fa fa-clipboard',
          actionEmitName: 'results'
        },
        {
          buttonText: 'CHANGE NICKNAME',
          outerButtonClass: 'text-nowrap',
          iconClass: 'fa fa-pen',
          actionEmitName: 'nickname'
        },
        {
          outerButtonClass: 'text-nowrap',
          iconClass: 'fa fa-times',
          buttonText: 'CANCEL HERO',
          actionEmitName: 'cancel'
        }
      ],
      initialNickname: '',
      showNicknameInput: false,
      dropdown: false,
      showButtonOptions: false,
      buttonClick: false
    }
  },
  computed: {
    ...mapGetters(['userData'])
  },
  watch: {
    'hero.Nickname': {
      handler: function () {
        if (this.hero.Nickname) {
          this.initialNickname = this.hero.Nickname
        } else this.initialNickname = ''
      },
      deep: true
    }
  },
  mounted () {
    this.initialNickname = this.hero.Nickname || ''
  },
  methods: {
    showNicknameInputElement () {
      this.showNicknameInput = true
      this.$nextTick(() => {
        this.$refs.initialNicknameInput.focus()
      })
    },
    addAnother () {
      this.$store.commit('storeClub', { ClubId: 0 })
      this.$router.push({ path: '/play', query: { page: 1 } })
    },
    toggleShowButtonOptions () {
      this.showButtonOptions = !this.showButtonOptions
    },
    showConfirmCancelHeroModal () {
      var msg = 'Are you sure you want to cancel this hero? You will no longer be in the draw and cannot restart this hero without creating it again via the checkout process.'
      this.$store.commit('GENERIC_CONFIRM_MODAL', {
        showing: true,
        confirmTitle: 'CANCEL HERO',
        confirmMessage: msg,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.toggleCancelBasket()
      })
    },
    async toggleCancelBasket () {
      this.showButtonOptions = false
      this.buttonClick = true
      try {
        const res = await basketService.toggleCancelBasket(this.hero.BasketOrdersId, this.userData.access_token)
        if (res.data === true) {
          this.$emit('getHeroes')
        }
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      this.$store.commit('CLOSE_GENERIC_CONFIRM_MODAL')
      this.buttonClick = false
    },
    async saveBasketNickname () {
      if (this.initialNickname === '') {
        this.initialNickname = this.hero.Nickname || ''
        this.showNicknameInput = false; return
      }
      this.showNicknameInput = false
      this.showButtonOptions = false
      this.buttonClick = true
      try {
        await basketService.saveBasketNickname(this.hero.BasketOrdersId, this.initialNickname, this.userData.access_token)
        this.$emit('getHeroes')
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      this.buttonClick = false
    },
    goToResults () {
      this.$router.push({ path: '/results', query: { hid: this.hero.BasketOrdersId } })
    }
  }
}
</script>

<style>
.emptyCardStyling {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  background: var(--bg-color);
  border-radius: 10px;
}
.editableCardContainer {
  height: 530px;
  max-height: 55vh;
  /* position: relative; */
  z-index: 0;
}
@media (max-width: 576px) {
  .editableCardContainer {
    max-height: 63vh;
  }
}
.editableCardActionsContainer {
  min-height: 25%;
  height: fit-content;
  width: 100%;
}
.nicknameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 30px;
  padding: 0 10px;
  border: var(--pr-color) 2px solid;
}
.formTextAlign > input {
  text-align: center;
}

</style>
